var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('div',{staticClass:"vector-widget py-3 pl-3",class:{ open: _vm.showVectorWidget, 'with-drag-bar': _vm.isElectron }},[_c('v-row',{attrs:{"no-gutters":""}},[_c('div',{staticClass:"page-title"},[_vm._v("Vector Management")]),_c('v-spacer'),_c('v-btn',{staticClass:"close-button",attrs:{"text":""},on:{"click":_vm.close}},[_c('v-icon',[_vm._v("mdi-close")])],1)],1),_vm._m(0),_c('v-row',{staticClass:"mt-2",attrs:{"no-gutters":""}},[_c('v-btn',{staticClass:"action-button",attrs:{"small":"","outlined":""},on:{"click":_vm.onCreateVector}},[_vm._v("Create Vector")])],1),_c('v-row',{staticClass:"mt-2",attrs:{"no-gutters":""}},[_c('v-data-table',{staticClass:"vector-list",attrs:{"items":_vm.masterMapData.vectors,"items-per-page":-1,"fixed-header":"","hide-default-footer":"","disable-pagination":"","headers":_vm.tableHeaders},scopedSlots:_vm._u([{key:"item.name",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"d-flex align-center"},[_c('div',{staticClass:"image-wrapper",style:({ 'background-color': item.data.color })}),_c('span',{staticClass:"vector-name pl-2"},[_vm._v(" "+_vm._s(item.data.name))])])]}},{key:"item.startPoint",fn:function(ref){
var item = ref.item;
return [_c('div',{staticStyle:{"font-size":"10px"}},[_vm._v(" "+_vm._s("[" + (Math.round(_vm.scaleUpCoordinate(item.data.startPoint.x))) + ", " + (Math.round(_vm.scaleUpCoordinate(item.data.startPoint.y))) + ", " + (Math.round(_vm.scaleUpCoordinate(item.data.startPoint.z))) + "]")+" ")])]}},{key:"item.direction",fn:function(ref){
var item = ref.item;
return [_c('div',{staticStyle:{"font-size":"10px"}},[_vm._v(" "+_vm._s(("[" + (Math.round(item.data.direction.x)) + ", " + (Math.round(item.data.direction.y)) + ", " + (Math.round(item.data.direction.z)) + "]"))+" ")])]}},{key:"item.length",fn:function(ref){
var item = ref.item;
return [_c('div',{staticStyle:{"font-size":"10px"}},[_vm._v(" "+_vm._s(Math.round(_vm.scaleUpCoordinate(item.data.length)) + 'm')+" ")])]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"d-flex"},[_c('v-btn',{attrs:{"small":"","dense":"","icon":""},on:{"click":function($event){return _vm.onEdit(item)}}},[_c('v-icon',[_vm._v("mdi-pencil-outline")])],1),_c('v-btn',{attrs:{"small":"","dense":"","icon":""},on:{"click":function($event){return _vm.onShowHide(item)}}},[_c('v-icon',[_vm._v(_vm._s(item.mesh.visible ? 'mdi-eye-off-outline' : 'mdi-eye'))])],1),_c('v-btn',{staticClass:"action-button delete",attrs:{"small":"","dense":"","icon":""},on:{"click":function($event){return _vm.onDelete(item)}}},[_c('v-icon',[_vm._v("mdi-trash-can-outline")])],1)],1)]}}])})],1)],1),_c('VectorCRUDWidget',{ref:"vectorCRUDWidget"}),_c('ConfirmationDialog',{ref:"confirmationDialog"})],1)}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"page-title--sub"},[_vm._v("!!! Note this map uses "),_c('a',{attrs:{"href":"https://github.com/Tmktahu/IPS","target":"_blank"}},[_vm._v("IPS Coordinates")]),_vm._v(" !!!")])}]

export { render, staticRenderFns }