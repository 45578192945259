/* eslint-disable prettier/prettier */
/* eslint-disable id-length */
import { v4 as uuidv4 } from 'uuid';

export const ORIGIN_STATIONS = [
  { name: 'Origin Station 1', type: 'origin_station', color: 'lime', position: { x: 30000, y: -5000, z: -60000 }, id: uuidv4(), hide: false, autoScale: false, icon: 'station1', group: 'Eos', description: 'This is an origin station that is positioned around the Eos Warp Gate. It serves as a spawn location for new players and currently houses many ship shops and functional structures such as:\n- Ship Design structure\n- Refueling structures\n- Ore selling terminals and drop-off locations\n- Auction house' },
  { name: 'Origin Station 2', type: 'origin_station', color: 'lime', position: { x: 25000, y: -15000, z: -55000 }, id: uuidv4(), hide: false, autoScale: false, icon: 'station1', group: 'Eos', description: 'This is an origin station that is positioned around the Eos Warp Gate. It serves as a spawn location for new players and currently houses many ship shops and functional structures such as:\n- Ship Design structure\n- Refueling structures\n- Ore selling terminals and drop-off locations\n- Auction house' },
  { name: 'Origin Station 3', type: 'origin_station', color: 'lime', position: { x: 25000, y: -25000, z: -50000 }, id: uuidv4(), hide: false, autoScale: false, icon: 'station1', group: 'Eos', description: 'This is an origin station that is positioned around the Eos Warp Gate. It serves as a spawn location for new players and currently houses many ship shops and functional structures such as:\n- Ship Design structure\n- Refueling structures\n- Ore selling terminals and drop-off locations\n- Auction house' },
  { name: 'Origin Station 4', type: 'origin_station', color: 'lime', position: { x: 20000, y: -35000, z: -40000 }, id: uuidv4(), hide: false, autoScale: false, icon: 'station1', group: 'Eos', description: 'This is an origin station that is positioned around the Eos Warp Gate. It serves as a spawn location for new players and currently houses many ship shops and functional structures such as:\n- Ship Design structure\n- Refueling structures\n- Ore selling terminals and drop-off locations\n- Auction house' },
  { name: 'Origin Station 5', type: 'origin_station', color: 'lime', position: { x: 15000, y: -40000, z: -30000 }, id: uuidv4(), hide: false, autoScale: false, icon: 'station1', group: 'Eos', description: 'This is an origin station that is positioned around the Eos Warp Gate. It serves as a spawn location for new players and currently houses many ship shops and functional structures such as:\n- Ship Design structure\n- Refueling structures\n- Ore selling terminals and drop-off locations\n- Auction house' },
  { name: 'Origin Station 6', type: 'origin_station', color: 'lime', position: { x: 10000, y: -45000, z: -20000 }, id: uuidv4(), hide: false, autoScale: false, icon: 'station1', group: 'Eos', description: 'This is an origin station that is positioned around the Eos Warp Gate. It serves as a spawn location for new players and currently houses many ship shops and functional structures such as:\n- Ship Design structure\n- Refueling structures\n- Ore selling terminals and drop-off locations\n- Auction house' },
  { name: 'Origin Station 7', type: 'origin_station', color: 'lime', position: { x: 10000, y: -50000, z: -10000 }, id: uuidv4(), hide: false, autoScale: false, icon: 'station1', group: 'Eos', description: 'This is an origin station that is positioned around the Eos Warp Gate. It serves as a spawn location for new players and currently houses many ship shops and functional structures such as:\n- Ship Design structure\n- Refueling structures\n- Ore selling terminals and drop-off locations\n- Auction house' },
  { name: 'Origin Station 8', type: 'origin_station', color: 'lime', position: { x: 10000, y: -50100, z: 0 }, id: uuidv4(), hide: false, autoScale: false, icon: 'station1', group: 'Eos', description: 'This is an origin station that is positioned around the Eos Warp Gate. It serves as a spawn location for new players and currently houses many ship shops and functional structures such as:\n- Ship Design structure\n- Refueling structures\n- Ore selling terminals and drop-off locations\n- Auction house' },
  { name: 'Origin Station 9', type: 'origin_station', color: 'lime', position: { x: 10000, y: -50000, z: 10000 }, id: uuidv4(), hide: false, autoScale: false, icon: 'station1', group: 'Eos', description: 'This is an origin station that is positioned around the Eos Warp Gate. It serves as a spawn location for new players and currently houses many ship shops and functional structures such as:\n- Ship Design structure\n- Refueling structures\n- Ore selling terminals and drop-off locations\n- Auction house' },
  { name: 'Origin Station 10', type: 'origin_station', color: 'lime', position: { x: 10000, y: -45000, z: 20000 }, id: uuidv4(), hide: false, autoScale: false, icon: 'station1', group: 'Eos', description: 'This is an origin station that is positioned around the Eos Warp Gate. It serves as a spawn location for new players and currently houses many ship shops and functional structures such as:\n- Ship Design structure\n- Refueling structures\n- Ore selling terminals and drop-off locations\n- Auction house' },
  { name: 'Origin Station 11', type: 'origin_station', color: 'lime', position: { x: 15000, y: -40000, z: 30000 }, id: uuidv4(), hide: false, autoScale: false, icon: 'station1', group: 'Eos', description: 'This is an origin station that is positioned around the Eos Warp Gate. It serves as a spawn location for new players and currently houses many ship shops and functional structures such as:\n- Ship Design structure\n- Refueling structures\n- Ore selling terminals and drop-off locations\n- Auction house' },
  { name: 'Origin Station 12', type: 'origin_station', color: 'lime', position: { x: 20000, y: -35000, z: 40000 }, id: uuidv4(), hide: false, autoScale: false, icon: 'station1', group: 'Eos', description: 'This is an origin station that is positioned around the Eos Warp Gate. It serves as a spawn location for new players and currently houses many ship shops and functional structures such as:\n- Ship Design structure\n- Refueling structures\n- Ore selling terminals and drop-off locations\n- Auction house' },
  { name: 'Origin Station 13', type: 'origin_station', color: 'lime', position: { x: 25000, y: -25000, z: 50000 }, id: uuidv4(), hide: false, autoScale: false, icon: 'station1', group: 'Eos', description: 'This is an origin station that is positioned around the Eos Warp Gate. It serves as a spawn location for new players and currently houses many ship shops and functional structures such as:\n- Ship Design structure\n- Refueling structures\n- Ore selling terminals and drop-off locations\n- Auction house' },
  { name: 'Origin Station 14', type: 'origin_station', color: 'lime', position: { x: 25000, y: -15000, z: 55000 }, id: uuidv4(), hide: false, autoScale: false, icon: 'station1', group: 'Eos', description: 'This is an origin station that is positioned around the Eos Warp Gate. It serves as a spawn location for new players and currently houses many ship shops and functional structures such as:\n- Ship Design structure\n- Refueling structures\n- Ore selling terminals and drop-off locations\n- Auction house' },
  { name: 'Origin Station 15', type: 'origin_station', color: 'lime', position: { x: 30000, y: -5000, z: 60000 }, id: uuidv4(), hide: false, autoScale: false, icon: 'station1', group: 'Eos', description: 'This is an origin station that is positioned around the Eos Warp Gate. It serves as a spawn location for new players and currently houses many ship shops and functional structures such as:\n- Ship Design structure\n- Refueling structures\n- Ore selling terminals and drop-off locations\n- Auction house' },
  { name: 'Origin Station 16', type: 'origin_station', color: 'lime', position: { x: 30000, y: 5000, z: 60000 }, id: uuidv4(), hide: false, autoScale: false, icon: 'station1', group: 'Eos', description: 'This is an origin station that is positioned around the Eos Warp Gate. It serves as a spawn location for new players and currently houses many ship shops and functional structures such as:\n- Ship Design structure\n- Refueling structures\n- Ore selling terminals and drop-off locations\n- Auction house' },
  { name: 'Origin Station 17', type: 'origin_station', color: 'lime', position: { x: 25000, y: 15000, z: 55000 }, id: uuidv4(), hide: false, autoScale: false, icon: 'station1', group: 'Eos', description: 'This is an origin station that is positioned around the Eos Warp Gate. It serves as a spawn location for new players and currently houses many ship shops and functional structures such as:\n- Ship Design structure\n- Refueling structures\n- Ore selling terminals and drop-off locations\n- Auction house' },
  { name: 'Origin Station 18', type: 'origin_station', color: 'lime', position: { x: 25000, y: 25000, z: 50000 }, id: uuidv4(), hide: false, autoScale: false, icon: 'station1', group: 'Eos', description: 'This is an origin station that is positioned around the Eos Warp Gate. It serves as a spawn location for new players and currently houses many ship shops and functional structures such as:\n- Ship Design structure\n- Refueling structures\n- Ore selling terminals and drop-off locations\n- Auction house' },
  { name: 'Origin Station 19', type: 'origin_station', color: 'lime', position: { x: 20000, y: 35000, z: 40000 }, id: uuidv4(), hide: false, autoScale: false, icon: 'station1', group: 'Eos', description: 'This is an origin station that is positioned around the Eos Warp Gate. It serves as a spawn location for new players and currently houses many ship shops and functional structures such as:\n- Ship Design structure\n- Refueling structures\n- Ore selling terminals and drop-off locations\n- Auction house' },
  { name: 'Origin Station 20', type: 'origin_station', color: 'lime', position: { x: 15000, y: 40000, z: 30000 }, id: uuidv4(), hide: false, autoScale: false, icon: 'station1', group: 'Eos', description: 'This is an origin station that is positioned around the Eos Warp Gate. It serves as a spawn location for new players and currently houses many ship shops and functional structures such as:\n- Ship Design structure\n- Refueling structures\n- Ore selling terminals and drop-off locations\n- Auction house' },
  { name: 'Origin Station 21', type: 'origin_station', color: 'lime', position: { x: 10000, y: 45000, z: 20000 }, id: uuidv4(), hide: false, autoScale: false, icon: 'station1', group: 'Eos', description: 'This is an origin station that is positioned around the Eos Warp Gate. It serves as a spawn location for new players and currently houses many ship shops and functional structures such as:\n- Ship Design structure\n- Refueling structures\n- Ore selling terminals and drop-off locations\n- Auction house' },
  { name: 'Origin Station 22', type: 'origin_station', color: 'lime', position: { x: 10000, y: 50000, z: 10000 }, id: uuidv4(), hide: false, autoScale: false, icon: 'station1', group: 'Eos', description: 'This is an origin station that is positioned around the Eos Warp Gate. It serves as a spawn location for new players and currently houses many ship shops and functional structures such as:\n- Ship Design structure\n- Refueling structures\n- Ore selling terminals and drop-off locations\n- Auction house' },
  { name: 'Origin Station 23', type: 'origin_station', color: 'lime', position: { x: 10000, y: 50000, z: 0 }, id: uuidv4(), hide: false, autoScale: false, icon: 'station1', group: 'Eos', description: 'This is an origin station that is positioned around the Eos Warp Gate. It serves as a spawn location for new players and currently houses many ship shops and functional structures such as:\n- Ship Design structure\n- Refueling structures\n- Ore selling terminals and drop-off locations\n- Auction house' },
  { name: 'Origin Station 24', type: 'origin_station', color: 'lime', position: { x: 10000, y: 50000, z: -10000 }, id: uuidv4(), hide: false, autoScale: false, icon: 'station1', group: 'Eos', description: 'This is an origin station that is positioned around the Eos Warp Gate. It serves as a spawn location for new players and currently houses many ship shops and functional structures such as:\n- Ship Design structure\n- Refueling structures\n- Ore selling terminals and drop-off locations\n- Auction house' },
  { name: 'Origin Station 25', type: 'origin_station', color: 'lime', position: { x: 10000, y: 45000, z: -20000 }, id: uuidv4(), hide: false, autoScale: false, icon: 'station1', group: 'Eos', description: 'This is an origin station that is positioned around the Eos Warp Gate. It serves as a spawn location for new players and currently houses many ship shops and functional structures such as:\n- Ship Design structure\n- Refueling structures\n- Ore selling terminals and drop-off locations\n- Auction house' },
  { name: 'Origin Station 26', type: 'origin_station', color: 'lime', position: { x: 15000, y: 40000, z: -30000 }, id: uuidv4(), hide: false, autoScale: false, icon: 'station1', group: 'Eos', description: 'This is an origin station that is positioned around the Eos Warp Gate. It serves as a spawn location for new players and currently houses many ship shops and functional structures such as:\n- Ship Design structure\n- Refueling structures\n- Ore selling terminals and drop-off locations\n- Auction house' },
  { name: 'Origin Station 27', type: 'origin_station', color: 'lime', position: { x: 20000, y: 35000, z: -40000 }, id: uuidv4(), hide: false, autoScale: false, icon: 'station1', group: 'Eos', description: 'This is an origin station that is positioned around the Eos Warp Gate. It serves as a spawn location for new players and currently houses many ship shops and functional structures such as:\n- Ship Design structure\n- Refueling structures\n- Ore selling terminals and drop-off locations\n- Auction house' },
  { name: 'Origin Station 28', type: 'origin_station', color: 'lime', position: { x: 25000, y: 25000, z: -50000 }, id: uuidv4(), hide: false, autoScale: false, icon: 'station1', group: 'Eos', description: 'This is an origin station that is positioned around the Eos Warp Gate. It serves as a spawn location for new players and currently houses many ship shops and functional structures such as:\n- Ship Design structure\n- Refueling structures\n- Ore selling terminals and drop-off locations\n- Auction house' },
  { name: 'Origin Station 29', type: 'origin_station', color: 'lime', position: { x: 25000, y: 15000, z: -55000 }, id: uuidv4(), hide: false, autoScale: false, icon: 'station1', group: 'Eos', description: 'This is an origin station that is positioned around the Eos Warp Gate. It serves as a spawn location for new players and currently houses many ship shops and functional structures such as:\n- Ship Design structure\n- Refueling structures\n- Ore selling terminals and drop-off locations\n- Auction house' },
  { name: 'Origin Station 30', type: 'origin_station', color: 'lime', position: { x: 30000, y: 5000, z: -60000 }, id: uuidv4(), hide: false, autoScale: false, icon: 'station1', group: 'Eos', description: 'This is an origin station that is positioned around the Eos Warp Gate. It serves as a spawn location for new players and currently houses many ship shops and functional structures such as:\n- Ship Design structure\n- Refueling structures\n- Ore selling terminals and drop-off locations\n- Auction house' },
];

export const TRANSMITTER_STATIONS = [
  { name: 'North', type: 'transmitter_station', color: 'red', position: { x: 20000, y: 0, z: 65000 }, id: uuidv4(), hide: false, autoScale: false, icon: 'satellite', group: 'Eos', description: 'This is a transmitter station used by position systems such as IPS and ISAN to calculate 3D coordinates.' },
  { name: 'South', type: 'transmitter_station', color: 'white', position: { x: 35000, y: 0, z: -65000 }, id: uuidv4(), hide: false, autoScale: false, icon: 'satellite', group: 'Eos', description: 'This is a transmitter station used by position systems such as IPS and ISAN to calculate 3D coordinates.' },
  { name: 'East', type: 'transmitter_station', color: 'yellow', position: { x: -20000, y: -60000, z: 0 }, id: uuidv4(), hide: false, autoScale: false, icon: 'satellite', group: 'Eos', description: 'This is a transmitter station used by position systems such as IPS and ISAN to calculate 3D coordinates.' },
  { name: 'West', type: 'transmitter_station', color: 'purple', position: { x: -5000, y: 60000, z: 0 }, id: uuidv4(), hide: false, autoScale: false, icon: 'satellite', group: 'Eos', description: 'This is a transmitter station used by position systems such as IPS and ISAN to calculate 3D coordinates.' },
];
