var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('div',{staticClass:"waypoint-widget py-3 pl-3",class:{ open: _vm.showWaypointWidget, 'with-drag-bar': _vm.isElectron }},[_c('v-row',{attrs:{"no-gutters":""}},[_c('div',{staticClass:"page-title"},[_vm._v("Waypoint Management")]),_c('v-spacer'),_c('v-btn',{staticClass:"close-button",attrs:{"text":""},on:{"click":_vm.close}},[_c('v-icon',[_vm._v("mdi-close")])],1)],1),_vm._m(0),_c('v-row',{staticClass:"mt-2",attrs:{"no-gutters":""}},[_c('v-btn',{staticClass:"action-button mr-2",attrs:{"small":"","outlined":""},on:{"click":_vm.onCreateWaypoint}},[_vm._v("Create Waypoint")]),_c('v-btn',{staticClass:"action-button",attrs:{"small":"","outlined":""},on:{"click":_vm.onResetDefaults}},[_vm._v("Reset Default Points")])],1),_c('v-row',{staticClass:"mt-2",attrs:{"no-gutters":""}},[_c('v-data-table',{staticClass:"waypoint-list",attrs:{"items":_vm.masterMapData.points,"items-per-page":-1,"fixed-header":"","hide-default-footer":"","disable-pagination":"","headers":_vm.tableHeaders,"group-by":"data.group"},scopedSlots:_vm._u([{key:"item.name",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"d-flex align-center"},[_c('div',{staticClass:"image-wrapper",style:({ 'background-color': item.data.color })},[_c('img',{staticStyle:{"filter":"invert(1)"},attrs:{"src":_vm.ICON_MAP[item.data.icon].workingFilePath,"contain":"","width":"20px","height":"20px"}})]),_c('span',{staticClass:"waypoint-name pl-2"},[_vm._v(" "+_vm._s(item.data.name))])])]}},{key:"item.position",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"d-flex align-center"},[_c('span',{staticClass:"waypoint-coord"},[_vm._v(" "+_vm._s("[" + (Math.round(_vm.scaleUpCoordinate(item.data.position.x))) + "," + (Math.round(_vm.scaleUpCoordinate(item.data.position.y))) + "," + (Math.round(_vm.scaleUpCoordinate(item.data.position.z))) + "]")+" ")])])]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"d-flex"},[_c('v-btn',{attrs:{"small":"","dense":"","icon":""},on:{"click":function($event){return _vm.onEdit(item)}}},[_c('v-icon',[_vm._v("mdi-pencil-outline")])],1),_c('v-btn',{attrs:{"small":"","dense":"","icon":""},on:{"click":function($event){return _vm.onView(item)}}},[_c('v-icon',[_vm._v("mdi-magnify")])],1),_c('v-btn',{attrs:{"small":"","dense":"","icon":""},on:{"click":function($event){return _vm.onShowHide(item)}}},[_c('v-icon',[_vm._v(_vm._s(item.mesh.visible ? 'mdi-eye-off-outline' : 'mdi-eye'))])],1),_c('v-btn',{staticClass:"action-button delete",attrs:{"small":"","dense":"","icon":""},on:{"click":function($event){return _vm.onDelete(item)}}},[_c('v-icon',[_vm._v("mdi-trash-can-outline")])],1)],1)]}},{key:"no-data",fn:function(){return [_c('div',{staticClass:"d-flex flex-column"},[_c('div',{staticClass:"pt-2"},[_vm._v("There was a problem.")]),_c('div',{staticClass:"pt-2 pb-4"},[_vm._v("Please try going back to the main Atlas window and "),_c('br'),_vm._v("clicking the \"Waypoints\" navigation button again.")])])]},proxy:true},{key:"group.header",fn:function(ref){
var group = ref.group;
var toggle = ref.toggle;
var isOpen = ref.isOpen;
return [_c('td',{staticClass:"group-header-row",attrs:{"colspan":"3"},on:{"click":toggle}},[_c('div',{staticClass:"d-flex justify-center align-center group-header-text"},[_c('v-icon',{staticClass:"mr-3",attrs:{"size":"20"}},[_vm._v(_vm._s(isOpen ? 'mdi-arrow-expand-up' : 'mdi-arrow-expand-down'))]),_c('div',{staticClass:"group-header"},[_vm._v(" \""+_vm._s(group)+"\" Waypoints ")]),_c('v-icon',{staticClass:"ml-3",attrs:{"size":"20"}},[_vm._v(_vm._s(isOpen ? 'mdi-arrow-expand-up' : 'mdi-arrow-expand-down'))])],1)])]}}])})],1)],1),_c('WaypointCRUDWidget',{ref:"waypointCRUDWidget"}),_c('ConfirmationDialog',{ref:"confirmationDialog"})],1)}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"page-title--sub"},[_vm._v("!!! Note this map uses "),_c('a',{attrs:{"href":"https://github.com/Tmktahu/IPS","target":"_blank"}},[_vm._v("IPS Coordinates")]),_vm._v(" !!!")])}]

export { render, staticRenderFns }